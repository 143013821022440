<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      :items-per-page="5"
      item-key="id"
      sort-by="name"
      calculate-widths
      class="elevation-4"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar flat>
          <h3>Movimientos de inventario</h3>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="pa-2">
          <v-col cols="6" md="3">
            <v-select
              v-model="even_code"
              :items="events_list"
              item-value="code"
              item-text="name"
              label="Tipo de Movimiento"
              placeholder="Codigo"
              clearable
              hide-details="auto"
              outlined
              :rules="f_required"
              @change="filter_data"
              dense
            />
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <v-text-field
              v-model="dateFrom"
              label="Desde"
              hide-details="auto"
              required
              dense
              outlined
              type="date"
            />
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <v-text-field
              v-model="dateTo"
              label="Hasta"
              hide-details="auto"
              required
              dense
              outlined
              type="date"
            />
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <v-btn color="success" @click="get_report">Buscar</v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.costo`]="{ item }">
        <span>{{ "$" + parseFloat(item.costo).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <span>{{ "$" + parseFloat(item.price).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.tcost`]="{ item }">
        <span>{{ "$" + parseFloat(item.tcost).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.tprice`]="{ item }">
        <span>{{ "$" + parseFloat(item.tprice).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="get_e_doc(item)"> mdi-magnify </v-icon>
      </template>
      <template slot="body.append">
        <tr>
          <th>Total</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-right">{{ sumTable("items", "quantity") }}</th>
          <th></th>
          <th class="text-right"></th>
          <th class="text-right">{{ "$" + sumTable("items", "tcost") }}</th>
          <th class="text-right">{{ "$" + sumTable("items", "tprice") }}</th>
        </tr>
      </template>
    </v-data-table>
    <noteViewer :item="item" :dialog="dialog" @close="dialog = false" />
  </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";
import noteViewer from "../components/doc_viewer_report.vue";

export default {
  components: { noteViewer },
  data() {
    return {
      even_code: "",
      errors: [],
      valid: false,
      filteredData: [],
      loading_status: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      imageUrl: null,
      dialog: false,
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "order_date",
          dataType: "text",
        },
        {
          text: "Documento",
          align: "start",
          sortable: true,
          value: "order_number",
          dataType: "text",
        },
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          value: "categoria",
          dataType: "text",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          value: "grupo",
          dataType: "text",
        },
        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "marca",
          dataType: "text",
        },
        {
          text: "Refe",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          value: "color",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Costo Uni",
          align: "end",
          sortable: true,
          value: "costo",
          dataType: "number",
        },
        {
          text: "Precio Uni",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
        {
          text: "Costo",
          align: "end",
          sortable: true,
          value: "tcost",
          dataType: "number",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "tprice",
          dataType: "number",
        },
        { text: "", align: "end", value: "actions", sortable: false, width: "5" },
      ],
      events_list: [
        { code: "OP", name: "Orden de Pedido" },
        { code: "OC", name: "Orden de Compra" },
        { code: "AJ", name: "Ajuste" },
        { code: "CP", name: "Cambiod e Precio" },
        { code: "EI", name: "Entrada Inventario" },
        { code: "SI", name: "Salida Inventario" },
        { code: "GI", name: "Ingreso garantia" },
        { code: "GS", name: "Salida Garantia" },
        { code: "PS", name: "Salida de Promocion" },
        { code: "PE", name: "Entrada de Promocion" },
        { code: "DV", name: "Devolucion" },
        { code: "CD", name: "Cambio de Documento" },
        { code: "TB", name: "Traslado Bodega" },
      ],
      items: [],
      item: { doc: null, items: [], payments: [] },
      dateFrom: getdays_ago(-30),
      dateTo: getToday(),
    };
  },
  mounted() {
    this.get_report();
  },
  methods: {
    filter_data() {
      // Assuming 'data' is your original array and 'filteredData' will hold the filtered items
      this.filteredData = this.items.filter((item) => {
        // Check if the item starts with 'OP'
        return item.order_number.startsWith(this.even_code);
      });
      console.log(this.filteredData);
    },
    sumTable(table, key) {
      return parseFloat(
        this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)
      ).toLocaleString(2);
    },
    get_report() {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        events: "'OP','OC','AJ','CP','EI','SI','GI','GS','PS','PE','DV','CD','TB'",
      };

      webserver("get_events", qry, (data) => {
        console.log(data);
        data.forEach((itm) => {
          itm.quantity = parseFloat(itm.quantity);
          itm.tcost = parseFloat(itm.quantity) * parseFloat(itm.costo);
          itm.tprice = parseFloat(itm.quantity) * parseFloat(itm.price);
        });
        this.loading_status = false;
        this.items = data;
        this.filteredData = data;
      });
    },
    get_e_doc(e) {
      console.log(e);

      var doc = {
        store: e.store,
        issueDate: e.order_date,
        did: e.order_number,
      };
      console.log(doc);
      webserver("get_invoice_fe", doc, (data) => {
        console.log(data);
        this.item = data;
        this.dialog = true;
        // data.doc.doc_type = "Invoice";
        // this.loading_vendors = false;
      });
    },
  },
};
</script>

<style></style>
